import axios from 'axios';

class Auth {

    constructor() {
        this._profile = null;
        this._eventTarget = new EventTarget();
        this._error = '';
    }

    get profile() {
        return this._profile;
    }

    get isLoggedIn() {
        return this._profile ? true : false;
    }

    get error() {
        return this._error;
    }

    get eventTarget() {
        return this._eventTarget;
    }

    async getProfile() {

        const token = window.localStorage.getItem('memberToken');
        let result = false;

        if (token) {

            try {
                // Use POST to bypass cache...
                const response = await axios.post('/index.php?option=com_members&task=member.profile&format=json', {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });
                this._profile = response.data.data;
                result = true;
            } catch (error) {
                this._error = error.response.data.message;
                result = false;
            }

        }

        const event = new CustomEvent('authReady');
        this._eventTarget.dispatchEvent(event);

        return result;
    }

    async login(username, password) {

        let result = false;

        try {
            const response = await axios.post('/index.php?option=com_members&task=member.login&format=json', {
                username,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            window.localStorage.setItem('memberToken', response.data.data.key);
            result = true;
        } catch (error) {
            this._error = error.response.data.message;
            result = false;
        }

        return result;
    }

    logout() {
        window.localStorage.removeItem('memberToken');
        this._profile = null;
        const event = new CustomEvent('authLoggedOut');
        this._eventTarget.dispatchEvent(event);
    }
}

export default new Auth();


